window.$ = window.$ || jQuery;
var App = App || {};

App.common = {
  $navInverter: $('.nav-inverter'),
  $body: $('body'),
  init: function () {

    // console.log('common init');
    // JavaScript to be fired on all pages
    AOS.init({
      'duration': 4000,
      'delay': 100
    });


    this.addListenners();


    //INSTAGRAM INFINITE SLIDE
    if ($('#infinite-slide-container').length !== 0) {
      var sf = new SlideInfinite();
      sf.init();
    }


    //ISOTOPE TEAM
    if ($('#grid--team').length !== 0) {
      var iso = new IsotopeElement($('#grid--team').find('.grid'));
      iso.init();
    }

    var isoInifinite;
    //ISOTOPE PRESS
    if ($('#grid--press').length !== 0) {
      isoInifinite = new IsotopeInfinite($('#grid--press').find('.grid'), 'fitRows', false);
      isoInifinite.init();
    }


    //ISOTOPE CLIENTS OLD
    if ($('#grid--clients-old').length !== 0) {
      isoInifinite = new IsotopeInfinite($('#grid--clients-old').find('.grid'), 'fitRows', true);
      isoInifinite.init();
    }


    //ISOTOPE CLIENTS NEW
    if ($('#grid--clients-new').length !== 0) {
      isoInifinite = new IsotopeInfinite($('#grid--clients-new').find('.grid'), 'masonry');
      isoInifinite.init();
    }


    //ISOTOPE CLIENTS NEW HOME
    if ($('#ref-home-isotope').length !== 0) {
      var params = {
        containerStyle: null,
        percentPosition: true,
        itemSelector: '.grid-item',
        filter: '*',
        transitionDuration: '0.4s',
        masonry: {columnWidth: '.grid-sizer'}
      };
      $('#ref-home-isotope').isotope(params);
    }

    $(window).trigger('resize');

  },

  addListenners: function () {
    var t = null;


    // $.stellar({
    //   horizontalScrolling: false,
    //   verticalScrolling: true,
    //   responsive: true,
    //   // scrollProperty: 'position',
    //   // positionProperty: 'position',
    //   // parallaxBackgrounds: true,
    //   // parallaxElements: false
    // });


    $('.trigger-scoll').click(function (e) {
      e.preventDefault();

      $('html, body').animate({scrollTop: $($(this).attr('href')).offset().top - App.settings.headOffset}, 1000);
    });


    $('.navbar-toggler').click(function () {
      if ($('body').hasClass('nav-open')) {
        $('body').removeClass('nav-open');
      } else {
        $('body').addClass('nav-open');
      }
    });


    $('.nav-protector').click(function () {
      if ($('body').hasClass('nav-open')) {
        $('body').removeClass('nav-open');
      }
    });

    // dropdown mouse enter
    if (!App.settings.isMobile.any()) {
      $('.dropdown').on('mouseleave', function () {
        var $this = $(this);
        t = setTimeout(function () {
          $this.removeClass("open");
        }, 1000);
      }).on('mouseenter', function () {
        clearTimeout(t);
        $('.dropdown').removeClass("open");
        $(this).addClass("open");
      });
    }

    // change nav sticky
    $(window).scroll(function () {

      if ($(window).scrollTop() > App.common.$navInverter.height() - 100) {
        App.common.$body.addClass('nav-inverted');
      } else {
        App.common.$body.removeClass('nav-inverted');
      }

      // parallax pages
      var nY, nSpacer = 50;
      if ($(window).width() > 1920) {
        nY = nSpacer - $(window).scrollTop() / 50;
      } else {
        nY = nSpacer - $(window).scrollTop() / 30;
      }


      // $('.parallax').css({'background-position': '50% ' + nY + '%'});

      // parallax home
      nY = $(window).scrollTop() / 2;
      //  $('.parallax-fullscreen').css({'background-position': '50% ' + nY + 'px'});

    });


    $('.partial-masthead-hero .sensor').swipe({
      swipe: function (event, direction) {
        if (direction === 'left') {
          $('#carousel-hero').carousel('next');
        }
        if (direction === 'right') {
          $('#carousel-hero').carousel('prev');
        }
      },
      allowPageScroll: "vertical"
    });

    $('.partial-masthead-basic').swipe({
      swipe: function (event, direction) {
        if (direction === 'left') {
          location.href = $next_url;
        }
        if (direction === 'right') {
          location.href = $prev_url;
        }
      },
      allowPageScroll: "vertical"
    });


    $(window).resize(function () {
      $(window).trigger('scroll');

      $('.filter-button-group-mobile').find('.dropdown-menu').width($(window).width());

      if (!$('body').hasClass('home') && $(window).width() < 768) {
        $('.top-bar').hide();
      }

      if ($(window).width() < 768) {
        App.settings.headOffset = 50;
        if (!$('body').hasClass('home')) {
          $('.parallax').css({'background-image': 'url(' + $cover_mobile_url + ')'});
        }
      } else {
        App.settings.headOffset = 170;
        if (!$('body').hasClass('home')) {
          $('.parallax').css({'background-image': 'url(' + $cover_url + ')'});
        }
      }
    });


    function moveSomething(e) {
      switch (e.keyCode) {
        case 37:
          $('#carousel-hero').carousel('prev');
          break;
        case 39:
          $('#carousel-hero').carousel('next');
          break;
      }
    }

    window.addEventListener("keydown", moveSomething, false);
  },
  finalize: function () {
    // JavaScript to be fired on all pages, after page specific JS is fired
  }
};


