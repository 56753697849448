window.$ = window.$ || jQuery;
var App = App || {};
App.presse = {
  init: function () {
    console.log('init presse');
  },
  finalize: function () {
    // JavaScript to be fired on the home page, after the init JS
  }
};

