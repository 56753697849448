function IsotopeElement($scope) {
  var _that = this;
  _that.$scope = $scope;
  var nBaseHeight = 0;

  this.init = function () {
    _that.replaceElements();
    this.addListeners();
    this.$scope.isotope(
      {
        layoutMode: 'fitRows',
        itemSelector: '.grid-item',
        // transitionDuration: '0s',
        // stagger: 0,
         hiddenStyle: {
           opacity: 0
        //   transform: 'scale(1)'
         },
         visibleStyle: {
           opacity: 1
        //   transform: 'scale(1)'
         }
      }
    );

    if (window.location.hash.length > 1) {
      setTimeout(function () {
        $('' + window.location.hash + ' .grid-item-overlay').trigger('click');
      }, 1000);
    }

  };


  this.resize = function () {
    _that.replaceElements();
    _that.$scope.isotope('layout');
  };

  this.replaceElements = function(){
    _that.$scope.find('.grid-item').each(function () {
      _that.nBaseHeight = $(this).width();
      $(this).css({
        height: $(this).width() + 'px',
        minHeight: $(this).width() + 'px'
      });
    });

    _that.$scope.find('.grid-item-collapse').each(function () {
      $(this).css({
        'width': $('.container').width() + 'px',
        'left': _that.$scope.offset().left - $(this).parent().offset().left + 'px'
      });
    });
    _that.$scope.find('.grid-item-carousel').each(function () {
      $(this).css({
        height: _that.nBaseHeight + 'px',
      });
    });
  };


  this.reset = function () {
    _that.$scope.removeClass('opened');
    _that.$scope.find('.grid-item').removeClass('open');
    _that.$scope.find('.grid-item-content').each(function () {
      $(this).css({
        'height': _that.nBaseHeight + 'px'
      });
    });
    _that.$scope.find('.grid-item').each(function () {
      $(this).css({
        'height': _that.nBaseHeight + 'px',
        'min-height': _that.nBaseHeight + 'px'
      });
    });
    _that.$scope.isotope('layout');
    _that.$scope.find('.carousel').carousel(0);
    _that.$scope.find('.carousel').carousel('pause');
  };

  this.openItem = function (el) {
    _that.resize();
    _that.$scope.addClass('opened');
    var nH = el.height() + el.find('.grid-item-collapse').height();
    el.addClass('open');

    el.find('.grid-item-content').css({
      'height': nH + 'px'
    });

    el.css({
      'height': nH + 'px',
      'min-height': nH + 'px'
    });

    _that.$scope.isotope('layout');

    setTimeout(function () {
      $('html, body').animate({scrollTop: el.offset().top - App.settings.headOffset}, 1000);
    }, 500);

    el.find('.carousel').carousel('cycle');

  };

  this.addListeners = function () {
    $(window).resize(function () {
      _that.resize();
      _that.reset();
    });


    _that.$scope.on('click', '.grid-item-overlay', function () {
      var el = $(this).parent().parent();
      if (el.hasClass('open')) {
        _that.reset();
      } else {
        _that.reset();
        _that.openItem(el);
      }
    });

    _that.$scope.on('click', '.close', _that.reset);

    _that.$scope.on('click', '.carousel-control', function (e) {
      e.preventDefault();
      if ($(this).hasClass('left')) {
        $(this).parent().carousel('prev');
      } else {
        $(this).parent().carousel('next');
      }
    });

    _that.$scope.on('layoutComplete', function () {

    });

    _that.$scope.on('arrangeComplete', function () {
      _that.$scope.find('.grid-item').each(function () {

        if ($(this).css('display') !== 'none') {
          $(this).addClass('opened');

        } else {
          $(this).removeClass('opened');
          // console.log('hide');

        }

      });
    });

    // filter items on button click
    $(document).on('click', '.filter-item', function () {
      $('.filter-item.active').removeClass('active');
      $(this).addClass('active');
      _that.reset();
      _that.$scope.isotope({filter: $(this).attr('data-filter')});
      $('#currentFilter').text($(this).text());
    });

  };
}






