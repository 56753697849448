/**
 * Created by rolan on 12/12/2016.
 */


var IsotopeInfinite = function ($scope, layoutMode, squareMode) {
    var _that = this;
    _that.$scope = $scope;
    _that.layoutMode = layoutMode;
    _that.xhrCall = false;
    _that.theend = false;
    _that.page = 2;
    _that.squareMode = squareMode;

    this.init = function () {


      var params = {
        containerStyle: null,
        percentPosition: true,
        itemSelector: '.grid-item',
        filter: '*',

        transitionDuration: '0.4s'
      };


      if (_that.layoutMode === 'fitRows') {
        params.layoutMode = 'fitRows';

        params.hiddenStyle = {
          opacity: 0,
          transform: 'scale(1)'
        };
        params.visibleStyle = {
          opacity: 1,
          transform: 'scale(1)'
        };


      } else if (_that.layoutMode === 'masonry') {
        params.masonry = {
          columnWidth: '.grid-sizer',

        };
      }
      _that.$scope.isotope(params);


      function generateItems() {
        _that.xhrCall = true;
        $('body').addClass('xhr-loading');
        $.ajax({
          url: IndigoAjax.ajaxurl,
          method: 'post',
          data: {
            nbItems: _that.$scope.data('nbitems'),
            action: _that.$scope.data('xhraction'),
            page: _that.page
          }
        }).done(function (data) {
          // console.log(data.data.html);
          if (data.data === "") {
            _that.theend = true;
          }

          if (data.success) {

            var $newel = $(data.data.html);

            if (data.data.is_finished) {
              _that.theend = true;
            }
            _that.$scope.append($newel);
            _that.$scope.isotope('appended', $newel);
            $(window).trigger('resize');
            _that.page++;

          } else {
            console.log('error ajax');
          }
        }).fail(function () {
        }).always(function () {
          // console.log('always');
          _that.xhrCall = false;
          $('body').removeClass('xhr-loading');
        });
      }

// SimpleInfiniteScroll
      function Infinite(e) {
        if ((e.type === 'scroll') || e.type === 'click') {
          var bottom = _that.$scope.offset().top + _that.$scope.height();
          var docBottom = $(window).height() + window.pageYOffset;

          if (bottom < docBottom && _that.xhrCall === false && _that.theend === false) {
            _that.xhrCall = true;
            generateItems();
          }
        }
      }


      $(document).on('click', '.filter-item', function () {
        $('.filter-item.active').removeClass('active');
        $(this).addClass('active');
        _that.$scope.isotope({filter: $(this).attr('data-filter')});
        $('#currentFilter').text($(this).text());
      });

      var resizeTimer;

      $(window).resize(function () {

        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
          if (_that.squareMode === true) {
            _that.$scope.find('.grid-item').height(_that.$scope.find('.grid-item').width());
          }

          _that.$scope.isotope('layout');

        }, 250);


      });

      $(window).scroll(Infinite);

    }
    ;
  }
  ;


















