window.$ = window.$ || jQuery;
var App = App || {};
App.home = {
  init: function () {





  },
  finalize: function () {
    // JavaScript to be fired on the home page, after the init JS
  }
};

