'use strict';

var SlideInfinite = function () {


  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) {
          descriptor.writable = true;
        }
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) {
        defineProperties(Constructor.prototype, protoProps);
      }
      if (staticProps) {
        defineProperties(Constructor, staticProps);
      }
      return Constructor;
    };
  }();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var InfiniteSlider = function () {
    console.log('init slide infinite');
    function InfiniteSlider() {
      var animTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '10000';
      var selector = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '.slider';
      var container = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '#infinite-slide-container';

      _classCallCheck(this, InfiniteSlider);

      this.slider = document.querySelector(selector);
      this.container = document.querySelector(container);
      this.width = 0;
      this.oldWidth = 0;
      this.duration = parseInt(animTime);
      this.start = 0;
      this.refresh = 0; //0, 1, or 2, as in steps of the animation
      this._prevStop = false;
      this._stop = false;
      this._oldTimestamp = 0;
    }

    _createClass(InfiniteSlider, [{
      key: 'animate',
      value: function animate() {
        /* fix for browsers who like to run JS before images are loaded */
        var imgs = Array.prototype.slice.call(this.slider.querySelectorAll('img')).filter(function (img) {
          return img.naturalWidth === 0;
        });
        if (imgs.length > 0) {
          window.requestAnimationFrame(this.animate.bind(this));
          return;
        }

        /* Add another copy of the slideshow to the end, keep track of original width */
        this.oldWidth = this.slider.offsetWidth;
        var sliderText = '<span class="slider-extra">' + this.slider.innerHTML + '</span>';
        this.slider.innerHTML += sliderText;

        /* can have content still when we move past original slider */
        this.width = this.slider.offsetWidth;
        var minWidth = 2 * screen.width;

        /* Add more slideshows if needed to keep a continuous stream of content */
        while (this.width < minWidth) {
          this.slider.innerHTML += sliderText;
          this.width = this.slider.width;
        }
        this.slider.querySelector('.slider-extra:last-child').classList.add('slider-last');

        /* loop animation endlesssly (this is pretty cool) */
        window.requestAnimationFrame(this.controlAnimation.bind(this));
      }
    }, {
      key: 'halt',
      value: function halt() {
        this._stop = true;
        this._prevStop = false;
      }
    }, {
      key: 'go',
      value: function go() {
        this._stop = false;
        this._prevStop = true;
      }
    }, {
      key: 'stagnate',
      value: function stagnate() {
        this.container.style.overflowX = "scroll";
      }
    }, {
      key: 'controlAnimation',
      value: function controlAnimation(timestamp) {
        //console.log('this.stop: ' + this._stop + '\nthis.prevStop: ' + this._prevStop)
        if (this._stop === true) {
          if (this._prevStop === false) {
            this.slider.style.marginLeft = getComputedStyle(this.slider).marginLeft;
            this._prevStop = true;
            this._oldTimestamp = timestamp;
          }
        } else if (this._stop === false && this._prevStop === true) {
          this._prevStop = false;
          this.start = this.start + (timestamp - this._oldTimestamp);
        } else {
          //reset animation
          if (this.refresh >= 1) {
            this.start = timestamp;
            this.slider.style.marginLeft = 0;
            this.refresh = 0;
            window.requestAnimationFrame(this.controlAnimation.bind(this));
            return;
          }
          if (timestamp - this.start >= this.duration) {
            this.refresh = 1;
          }

          var perc = (timestamp - this.start) / this.duration * this.oldWidth;
          this.slider.style.marginLeft = -perc + 'px';
        }
        window.requestAnimationFrame(this.controlAnimation.bind(this));
        return;
      }
    }, {
      key: 'getIeWidth',
      value: function getIeWidth() {
        this.slider.style.marginLeft = '-99999px';
      }
    }, {
      key: 'ie11Fix',
      value: function ie11Fix() {
        this.slider.querySelector('.slider-last').style.position = 'absolute';
      }
    }]);

    return InfiniteSlider;
  }();


  function detectIE() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');

    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }


  this.init = function () {
    var slider = new InfiniteSlider(50000);
    var ie = detectIE();

//Dont animate under IE10, just place the images
    if (ie !== false && ie < 10) {
      slider.stagnate();
      return;
    }
//IE 11 and lower, fix for width *increasing* as more of the slider is shown
    if (ie !== false && ie < 12) {
      slider.getIeWidth();
    }

    slider.animate();
// document.querySelector('#slider-container').addEventListener('mouseenter', slider.halt.bind(slider));
// document.querySelector('#slider-container').addEventListener('mouseleave', slider.go.bind(slider));

    if (ie === 11) {
      setTimeout(slider.ie11Fix.bind(slider), 1000);
    }
  };


};

